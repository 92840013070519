import request from '@/utils/request';

export function getSublocalities(city, appId, contentTypeId) {
	var data = {'city':city,'app_id': appId,'contentType_id':contentTypeId};

	return request({
		url: '/sublocalities',
		method: 'get',
		params: data,
	});
}
